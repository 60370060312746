import React from 'react';
import './css/LandingPage.css';
import {useState , useEffect} from 'react';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
import MobileBannerImage from '../images/cleanLogo.png';
import PraiseNav from './PraiseNav';
import FooterImage from '../images/FooterImage.png';
import FooterText from './FooterText';

// import MarchIcon from '../images/Icon_March.png';
// import MayIcon from '../images/Daffodils.png';
// import JuneIcon from '../images/Hands.png';
// import AprilIcon from '../images/Icon_April.png';
import RaysOnly from '../images/RaysOnly.png';
import Button from 'react-bootstrap/Button';
// Import Calendar Image.png

function ComingUp() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
      <div className="gradient-background"></div>
      <div className="navbar-container">
        
      <PraiseNav />
      </div>
      <a href="/" className="text-decoration-none">
      <img src={bannerImage} alt="Praise-along Logo" className="banner-image mt-5 mb-5" />

      </a>
   <div className="row homepage-content-coming-up pt-5 pb-5">
  <div className="col"></div>
  <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
    <div className="row">
      <div className="col-md-2 d-none d-md-inline-block">
        <div className="vertical-center">
          {/* <Image src={MarchIcon} alt="NovemberBasket" className="month-images" /> */}
        </div>
      </div>
      <div className="col-md-12 col-12">
        <div className="vertical-center">
          <span className="red-text-coming-up">JANUARY:</span> "The Golden Rule" helps us to think about the most important rule Jesus taught people for living in harmony with God and each other, as we look forward to the year ahead.
        </div>
      </div>
    </div>
  </div>
  <div className="col"></div>
</div>

   
   <div className="row homepage-content-coming-up trans-white-bg pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">FEBRUARY:</span> "What Love Is" makes a great conversation starter for talking about the true characteristics of love in the light of healthy relationships with friends, families and other people in our lives.
            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={AprilIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
    <div className="row homepage-content-coming-up pt-5 pb-5">
  <div className="col"></div>
  <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
    <div className="row">
      <div className="col-md-2 d-none d-md-inline-block">
        {/* This div will be hidden on screens smaller than 'md' breakpoint */}
        <div className="vertical-center">
          {/* <Image src={MayIcon} alt="NovemberBasket" className="month-images" /> */}
        </div>
      </div>
      <div className="col-md-12 col-12">
        <div className="vertical-center">
          <span className="red-text-coming-up">MARCH:</span> "Give it up for Lent" reflects on the Christian tradition of giving up favorite foods for forty days during the season of Lent, helping people draw closer to God in the lead up to Easter. 
        </div>
      </div>
    </div>
  </div>
  <div className="col"></div>
</div>

   
   <div className="row homepage-content-coming-up trans-white-bg pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">APRIL:</span> "For He Is Risen" will engage us with the Easter Story through Latin style music, reflecting the great joy of the occasion as life and hope is celebrated in the resurrection of Jesus. 

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   
   <div className="row homepage-content-coming-up pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">MAY:</span> "In the Spring" encourages us take notice of the miracle of new life emerging all around us as we join in and praise-along with all creation!

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   
   <div className="row homepage-content-coming-up trans-white-bg pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">JUNE:</span> "Rich Diversity" will inspire us to appreciate with awe and wonder, the diverse world we live in, from the many species’ of plants and creatures to each individual person.

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   
   <div className="row homepage-content-coming-up pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">JULY:</span> "Moving Along" fosters a sense of hope for the future as we move on to our new classes and schools whilst being thankful for all we have learned along the way. 

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   
   <div className="row homepage-content-coming-up trans-white-bg pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">AUGUST:</span> "Me Time" is a song that we can sing throughout the summer holidays, reminding us to rest well and take good care of ourselves as we take a break from school. 

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   <div className="row homepage-content-coming-up pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">SEPTEMBER:</span> "Our Harvest Song" is a catchy number giving thanks to God for the bountiful goodness the earth provides. It will be available to sing in Harvest festivals throughout September and October. 

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   <div className="row homepage-content-coming-up trans-white-bg pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">OCTOBER:</span> "One True Light" is a worship song ideal for this time of year as the nights draw in. It portrays Jesus as “The light of the world” guiding all who follow Him like a light in the darkness. 

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   <div className="row homepage-content-coming-up pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">NOVEMBER:</span> "Pray for Peace" is a prayerful song remembering all who are affected by conflict in our world. It focuses on peace as people ‘pop on a poppy’ during this important time of reflection and remembrance.

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   <div className="row homepage-content-coming-up trans-white-bg pt-5 pb-5">
      <div className="col"></div>
      <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="vertical-center">
              <span className="red-text-coming-up">DECEMBER:</span> "We Can’t Wait" is a celebratory song for Advent that captures the anticipation as we count down the days, the hours, and even the minutes to Christmas Day!

            </div>
          </div>
          <div className="col-md-2 d-none d-md-inline-block"> {/* This div will be hidden on screens smaller than 'md' breakpoint */}
            <div className='vertical-center'>
              {/* <Image src={JuneIcon} alt="DecemberBasket" className="month-images" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>
   <div className="row homepage-content-coming-up mt-5">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-5 mb-5">
     
     
     <div className="download-button">
        <img src={RaysOnly} alt="Background" className="background-image" />
        <a href="/sing" className="text-decoration-none">

        <Button variant="primary border-0" className=""><span className="button-text">SING!</span></Button>
        </a>
      </div>
    </div>
    <div className="col"></div>
  </div>
<FooterText />

     
<div className="footer-image">
  <img src={FooterImage} alt="Footer" className="full-width-image" />
</div>
    </>
  );
}

export default ComingUp;
