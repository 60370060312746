// filepath: /c:/Users/Will/PraiseAlongAppAndAPIDev/PraiseAlongAppAndAPI/src/components/FooterText.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const FooterText = () => {
    return (
        <div className="container">
            <div className="footer-text">
                <div className="row">
                    <div className="col"></div>
                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0">
                        <a href="https://www.twitter.com/Praisealong">
                            <FontAwesomeIcon icon={faXTwitter} className="fa-4x text-grey m-2" />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100091842263809">
                            <FontAwesomeIcon icon={faFacebook} className="fa-4x text-grey m-2" />
                        </a>
                        <br />
                        Praise-along is a registered trademark of 12 Note Music Limited, Valley Works, Bacup Road, Todmorden, England, OL14 7PJ (Company Number 13395531) © 2022, 12 Note Music Limited, all rights reserved.
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </div>
    );
};

export default FooterText;